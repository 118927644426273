<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          医生开单收入统计
        </b-card-title>
        <b-card-sub-title>一次可查询31天医生开单收入</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="set"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="请选择时间范围"
          @on-close="doSomethingOnClose"
        >
        <template v-slot:default="{ date, handleDateChange }">
          <div>
            <input type="text" :value="date" readonly>
            <button @click="handleDateChange(date)">确定</button>
          </div>
        </template>
        </flat-pickr>
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="area"
        height="400"
        ref="demoChart"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import apexChatData from './apexChartData'
import { Mandarin } from 'flatpickr/dist/l10n/zh.js';
import Moment from "moment";
import { data } from 'vue-echarts';
export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
  },
  data() {
    return {
      apexChatData,
      rangePicker: [],
      rangeTime: [],
      set: {
        locale: Mandarin,
        dateFormat: 'Y-m-d',
        mode: 'range',
      },
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          zoom: { enabled: false }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          // opposite: isRtl
        },
        fill: {
          opacity: 1,
          type: 'solid',
        },
        tooltip: {
          shared: false,
        },
        colors: ['#001200'],
      },
    }
  },
  mounted() {  
    this.ceshi();
    this.timeframeorder();
  },  
  methods:{
    generateShades(baseColor, n) {
      // 解析基础颜色的RGB值
      let base = parseInt(baseColor.slice(1), 16); // 去掉#并转为十进制
      let r = (base >> 16) & 0xFF;
      let g = (base >> 8) & 0xFF;
      let b = base & 0xFF;

      let colors = [];
      let step = Math.floor(255 / (n * 1)); // 计算每次变化的步长
      for (let i = 0; i < n; i++) {
        // 改变RGB中的蓝色通道（B）值
        let newB = Math.max(0, Math.min(255, b + (i % 2 === 0 ? step : -step)));
        colors.push(`#${((r << 16) | (g << 8) | newB).toString(16).padStart(6, '0')}`);
      }
      return colors;
    },
    doSomethingOnClose(newRange){
      if(newRange && newRange.length === 2) {
        const firstDate = newRange[0];
        const secondDate = newRange[1];
        const maxDate = new Date(firstDate);
        maxDate.setDate(maxDate.getDate() + 30);
        // 处理第一个日期和第二个日期的逻辑
        const firstDayOfMonth = Moment(firstDate).format("YYYY-MM-DD")
        const lastDayOfMonth = Moment(secondDate).format("YYYY-MM-DD")
        if (secondDate > maxDate) {
          return this.$message({
            type: "danger",
            message: "查询范围最大不能超过31天",
          });
        }else if(this.rangeTime[0] === firstDayOfMonth && this.rangeTime[1] === lastDayOfMonth){
          console.log("日期没有更新")
        }else{
          this.rangeTime = [firstDayOfMonth,lastDayOfMonth]
          this.createcategories(firstDayOfMonth,lastDayOfMonth)
          this.timeframeorder();
        }
      }
    },
    //订单分类
    classifyAndSumOrders(orders, startDateStr, endDateStr) {  
      // 解析开始和结束日期  
      const startDate = new Date(startDateStr);  
      const endDate = new Date(endDateStr);  
      
      // 计算日期范围内的天数（考虑月份和年份）  
      const dateRange = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;  
      
      // 创建一个空对象用于存储按医生ID分类的订单  
      const doctorsOrders = {};  
      
      // 遍历所有订单  
      for (const order of orders) {  
        // 检查订单日期是否在指定范围内  
        const orderDate = new Date(order.start);  
        if (orderDate >= startDate && orderDate <= endDate) {  
          // 使用 doctorid 作为键来分类订单  
          const doctorId = order.doctorid;  
      
          // 如果该医生ID不存在于 doctorsOrders 中，则初始化一个对象  
          if (!doctorsOrders[doctorId]) {  
            doctorsOrders[doctorId] = {  
              doctorName: order.doctor, // 存储医生名字以便后续使用  
              data: new Array(dateRange).fill(0) // 初始化日期范围内的金额为0  
            };  
          }  
      
          // 计算日期偏移量以便存入正确的索引位置（考虑月份和年份）  
          const dateOffset = Math.floor((orderDate - startDate) / (1000 * 60 * 60 * 24));  
      
          // 累加对应日期的金额  
          if (dateOffset >= 0 && dateOffset < dateRange) {  
            doctorsOrders[doctorId].data[dateOffset] += parseFloat(order.istruetotal);  
          }  
        }  
      }  
      
      // 转换 doctorsOrders 为最终需要的格式  
      const result = Object.values(doctorsOrders).map(doctorData => ({  
        name: doctorData.doctorName, // 使用医生名字作为名称字段  
        data: doctorData.data // 直接使用已构建好的data数组  
      }));  
      
      return result;  
    }, 
    //生成图表日期
    createcategories(firstDayOfMonth,lastDayOfMonth){
      const datesArray = [];
      let currentDate = Moment(firstDayOfMonth);
      while (currentDate.isSameOrBefore(lastDayOfMonth, 'day')) {
        datesArray.push(currentDate.format('DD/MM'));
        currentDate.add(1, 'day');
      }
      return datesArray
    },
    ceshi(){
      const firstDayOfMonth = Moment().startOf('month').format('YYYY-MM-DD');
      const lastDayOfMonth = Moment().endOf('month').format('YYYY-MM-DD');
      this.rangePicker = [firstDayOfMonth,lastDayOfMonth]//默认时间范围
      this.rangeTime = [firstDayOfMonth,lastDayOfMonth]//默认时间范围
    },
    timeframeorder(){
      let days = {
        firstDayOfMonth:this.rangeTime[0],
        lastDayOfMonth:this.rangeTime[1]
      }
      this.$api.visit.timeframeorder(days).then((res) => {
        if (res.data.code === 200) {
          if(res.data.order.length > 0){
            const orderdata = this.classifyAndSumOrders(res.data.order,days.firstDayOfMonth,days.lastDayOfMonth)
            const arrardays = this.createcategories(days.firstDayOfMonth,days.lastDayOfMonth)
            const colorArray = this.generateShades("#0000FF",orderdata.length)
            this.$refs.demoChart.updateSeries(orderdata)
            this.$refs.demoChart.updateOptions({
            xaxis: {
              categories: arrardays
            },
            colors:colorArray
          })
          }else{
            this.$message.error('没有查询到该时间范围的数据');
          }
        }else{
          this.$message.error('网络错误');
        }
      });
    }
  },
  created() {
  },
}
</script>
