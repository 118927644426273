<template>
  <b-card no-body>
    <b-card-header class="align-items-baseline">
      <div>
        <b-card-title class="mb-25">
          医生全年累计开单
        </b-card-title>
        <b-card-text class="mb-0">
          总金额：{{ yeartotal }}
        </b-card-text>
      </div>
      <div class="elselectcss">
        <el-select v-model="doctor" placeholder="请选择">
          <el-option
            v-for="item in doctoroptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="year" placeholder="请选择年份">
          <el-option
            v-for="item in years"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button style="margin-left: 5px;" type="primary" plain @click="searchyearanddoctor">查 询</el-button>
      </div>
    </b-card-header>

    <b-card-body class="pb-0">

      <!-- apex chart -->
      <vue-apex-charts
        type="line"
        height="240"
        ref="classorderChart"
        :options="salesLine.chartOptions"
        :series="salesLine.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import store from '@/store/index'
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCardBody,
  },
  data() {
    return {
      yeartotal:"",
      doctoroptions:[],
      doctor:"",
      doctorid:"",
      year:"",
      years:[],
      value: '',
      salesLine: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['#df87f2'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                return val.toLocaleString();
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
    }
  },
  methods:{
    findIdByName(nameToFind,data) {  
      const foundItem = data.find(item => item.label === nameToFind);  
      return foundItem ? foundItem.value : null;  
    },
    //查询
    searchyearanddoctor(){
      this.yeasframeorder()
    },
    //订单分类
    classorder(orders){
      const result = {};
      orders.forEach(order => {
        const { doctorid, doctor, start, istruetotal } = order;
        const month = new Date(start).getMonth(); // 月份从0开始计数
        const amount = parseFloat(istruetotal);
        if (!result[doctorid]) {
          result[doctorid] = {
            name: doctor,
            data: Array(12).fill(0),
            total: 0
          };
        }
        result[doctorid].data[month] += amount;
        result[doctorid].total += amount;
      });
      const formattedResult = Object.values(result);
      return formattedResult
    },
    generateYearOptions() {  
      // 获取当前年份  
      const currentYear = new Date().getFullYear();  
      // 初始化选项数组  
      const options = [];  
      // 为当前年份和前两年生成选项  
      for (let i = 0; i < 3; i++) {  
        const year = currentYear - i;  
        options.push({  
          value: year.toString(),  
          label: year.toString()  
        });  
      }
      this.years = options
      this.year = this.years[0].value
    },
    //获取医生列表
    async getdoctor(){
      if(store.state.doctor.doctorlist.length === 0){
        this.doctoroptions =await store.dispatch('doctor/fetchDoctorlist')
      }else{
        this.doctoroptions = store.state.doctor.doctorlist
      }
      this.doctor = this.doctoroptions[0].value
      this.yeasframeorder()
    },
    yeasframeorder(){
      let data = {
        doctorid:this.doctor,
        year:this.year
      }
      this.$api.visit.yeasframeorder(data).then((res) => {
        if (res.data.code === 200) {
          if(res.data.order.length > 0){
            const newclassorder =  this.classorder(res.data.order)
            this.yeartotal = newclassorder[0].total
            this.$refs.classorderChart.updateSeries(newclassorder)
          }else{
            this.$message.error('没有查询到该年份的数据');
          }
        }else{
          this.$message.error('网络错误');
        }
      });
    }
  },
  mounted(){
    this.generateYearOptions()
    this.getdoctor()
  },
}
</script>
<style>
.elselectcss .el-select:nth-child(2) {  
  width: 100px;
  margin-left: 5px;
}  
</style>
